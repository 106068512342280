import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  username: string = '';
  ngOnInit(): void {
    console.log(sessionStorage.length);
    if(sessionStorage.userobject != undefined)
    this.username =  sessionStorage.firstname + ' ' + sessionStorage.lastname;
  }
  title = 'app';

}
