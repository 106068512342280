<!--footer-->
<div class="footer">
    <div class="wrapper">
   <div class="copyrights">Copyrights {{currentYear}} BASF Corporation</div>
   <div>
    <ul>
      <li><a href="https://www.basf.com/us/en/legal/e-business.html" target="_blank">⧁ Business</a></li>
      <li><a href="https://www.basf.com/us/en/legal/disclaimer.html" target="_blank">⧁ Disclaimer</a></li>
      <li><a href="https://www.basf.com/us/en.html#" target="_blank">⧁ Cookie Preference Center</a></li>
      <li><a href="https://www.basf.com/us/en/legal/credits.html" target="_blank">⧁ Credits</a></li>
      <li><a href="https://www.basf.com/us/en/legal/contact.html" target="_blank">⧁ Contact</a></li>
      <li><a href="https://www.basf.com/global/en/legal/data-protection-at-basf.html" target="_blank">⧁ Data Protection</a></li>
    </ul>
   </div>
  </div>
  </div>
