
<div >
  <p-toast></p-toast>
  <div class="progress-spinner" *ngIf="showspinner"  >
    <p-progressSpinner [style]="{width: '100px', height: '100px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"> </p-progressSpinner>
    <span class="progresstext"> Loading Conference Room Availability</span>
  </div>
  <div>
    <div style="display: flex; justify-content: flex-end" >
      <div class="col-md-6 sub-header-label" >
        Availability
         
         </div>
       
           <div class="col-md-6"  style="display: flex; justify-content: flex-end" >
               <p-calendar [(ngModel)]="dateValue" [showIcon]="false" styleClass="dateclass" (onSelect)="onDateChange(dateValue)" [inputStyle]="{'width':'150px','height':'42px', 'border-radius':'10px 0 0 10px','margin-left':'300px'}" ></p-calendar>
               <button  class ="arrowbtn" (click)="decrementDate()"><i class="pi pi-chevron-left" ></i></button>
               <button class ="arrowbtn" (click)="incrementDate()" style=" border-radius: 0 10px 10px 0;"><i class="pi pi-chevron-right" ></i></button>
            
         </div>
         </div>
         <div class="col-md-12 note-text" *ngIf="notetext" >
            Note: Double Click Available Room To Book
         </div>


         <br/>
        
      <p-table [value]="conferenceroomdata" [columns]="Cols" scrollDirection="horizontal" [scrollable]="true"  styleClass="p-mt-3" styleClass="p-datatable-gridlines" >
          <ng-template pTemplate="header" let-columns>
             
              <tr >
  
                <th [title]="col.startAndEndTime" *ngFor="let col of columns; let i=index"  pFrozenColumn  [frozen]="col.isFreez"
                       id="{{ col.startAndEndTime }}" style=" border-radius: 0px !important;" [style.border-left]="getborderstyle(col,i)+'px'">
                  <p *ngIf="col.startAndEndTime =='Rooms'" class="room p-0" style="font-size: 16px !important;" >{{ col.startAndEndTime  }}</p>
  
                  <div *ngIf="col.startAndEndTime !='Rooms'  && i%2 != 0"    [style.width]="'50px'"
  
                    >
                    <p  class="calendareventtext">{{ col.startAndEndTime | time24to12 }}
  
                  </p>
  
              
  
                                 </div>
                                 <div *ngIf="col.startAndEndTime !='Rooms'  && i%2 == 0"    [style.width]="'50px'"
  
                                 >
                                 <p   class="calendareventtext">
               
                               </p>
               
                           
               
                                              </div>
                </th>
  
  
  
              </tr>
  
               
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
              <tr [pSelectableRow]="rowData" style="height: 41.25px;">
  
                <td  *ngFor="let col of columns"  pFrozenColumn [hidden]="col.is_show"
                [frozen]="col.isFreez"  id="{{ col.startAndEndTime }}" (dblclick)="onRowDblClick($event, rowData)"  style=" width: 50px !important;border-radius: 0px !important;padding:0px" [ngStyle]="{'backgroundColor':col.startAndEndTime =='Rooms'? '#f1f1f1': '#FFFFF'}" [style.border-left-width]="hideborder+'px'"  >
                  <p *ngIf="col.startAndEndTime =='Rooms' " class="calendarroom" pTooltip="{{rowData['meetingroomname']}} "
                  [tooltipOptions]="tooltipOptions"   >{{rowData['meetingroomname']}}</p>
                   <div *ngIf=" col.startAndEndTime !='Rooms' && checkdata(rowData,col,i,columns)"    [style.padding-left]="leftpadding +'%'"  [style.width]="'50px'"
  
      >
      <p pTooltip={{meetingtooltip}} 
      [tooltipOptions]="tooltipOptions"  class="calendartext" [ngStyle]="{'backgroundColor':meetingtitle !='Lunch Time'? '#004a96': '#5e5e5e'}" [style]="{
          width: colwidth+'px',
          
  
      }" >{{reservedby}} 
  
  </p>
  
  
                   </div>

                </td>
  
  
              </tr>
            </ng-template>
           
      </p-table>
  <div class="note-text" style="text-align: center;">
    Use Above Scroll Bar for Time of Day</div>
    </div>
  
  </div>