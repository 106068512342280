<!--header-->
<div class="header">
    <div class="wrapper">
        <div class="logo-wrapper">
          <img src="../../../assets/images/Logo_transparent.png" routerLink="/login" class="header-logo"/>
        </div>
        <div class="logo-text-wrapper">
          <div class="logo-text" routerLink="/login">Conference Center Booking</div>
        </div>
        <div class="user-section" *ngIf="username">
          <p class="username"><span class="pi pi-user"></span> &nbsp; <b>{{username}}</b></p>
        </div>
  </div>
  </div>
