import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Users } from 'src/app/interface/users';
import { DataService } from 'src/app/services/data.service';
import { UserDetails } from '../../../interface/userDetails';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-userregistration',
  templateUrl: './userregistration.component.html',
  styleUrls: ['./userregistration.component.css']
})
export class UserregistrationComponent {
  userDialog!: boolean;
  userRegistrationForm!: FormGroup
  users!: Users[];

  user!: Users;

  selectedUser!: Users[];

  submitted!: boolean;

  tableData!: UserDetails[];
  CompanyData: any;
  Cols!: any[];
  userapiData: any;
  statementType: string = '';
  editform: boolean = false;
  showspinner!: boolean;
  responsemessage!: string;

  constructor(private dataService: DataService, private messageService: MessageService,
    private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.userRegistrationForm = this.formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      emailAddress: new FormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required]),
      companyName: new FormControl('', Validators.required),
      isAdmin: new FormControl(false, Validators.required),
      isNotifee: new FormControl(false, Validators.required)

    });
    this.Cols = [{
      "id": 1,
      "field": "id",
      "header": "Id",
      "is_show": false

    },
    {
      "id": 2,
      "field": "firstName",
      "header": "First Name",
      "is_show": true
    }, {
      "id": 3,
      "field": "lastName",
      "header": "Last Name",
      "is_show": true
    },
    {
      "id": 4,
      "field": "emailAddress",
      "header": "Email",
      "is_show": true
    },
    {
      "id": 5,
      "field": "companyName",
      "header": "Company",
      "is_show": true
    },
    {
      "id": 6,
      "field": "isAdmin",
      "header": "Admin",
      "is_show": true
    }, {
      "id": 7,
      "field": "isNotifee",
      "header": "Notify",
      "is_show": true
    }
    ]
    this.getCompanydetails();
    this.dataService.getUserList().subscribe((data: any) => {
      this.users = data;
      this.tableData = data;
    });
  }
  checkdisplay() {
    return this.userRegistrationForm.controls['companyName']?.value?.companyName == 'BASF' && this.userRegistrationForm.controls['emailAddress']?.value?.endsWith('basf.com');
  }
  checkemail(event: any) {
    console.log(event);
    if (event.target?.value.toLowerCase().match('basf.com$') != null)
      this.userRegistrationForm.controls['companyName'].setValue(this.CompanyData.find((i: any) => i.companyName == 'BASF'));
    this.userRegistrationForm.controls['isAdmin'].setValue(false)
    this.userRegistrationForm.controls['isNotifee'].setValue(false)
  }
  openNew() {
    this.user = {};
    this.submitted = false;
    this.userDialog = true;
    this.userRegistrationForm?.reset();
  }
  getCompanydetails() {
    this.dataService.getCompanyDetails().subscribe((data: any) => {
      this.CompanyData = data;

    });
  }
  deleteSelectedUsers() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected user?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.users = this.users.filter(val => !this.selectedUser.includes(val));
        this.selectedUser = [];
      }
    });
  }

  editUser(user: Users, type: string) {
    this.editform = true;
    this.bindFormData(user)
    this.user = { ...user };
    this.user.companyName = this.CompanyData.findIndex((i: any) => i.companyName == this.user.companyName).companyName;
    this.userDialog = true;

  }

  bindFormData(user: Users) {


    if (user.emailAddress != '') {
      this.userRegistrationForm.controls['companyName'].setValue(this.CompanyData.find((i: any) => i.companyName == user.companyName));
      this.userRegistrationForm.controls['firstName'].setValue(user.firstName)
      this.userRegistrationForm.controls['lastName'].setValue(user.lastName)
      this.userRegistrationForm.controls['emailAddress'].setValue(user.emailAddress?.toLowerCase())
      this.userRegistrationForm.controls['isAdmin'].setValue(user.isAdmin)
      this.userRegistrationForm.controls['isNotifee'].setValue(user.isNotifee)
    }



  }
  deleteUser(user: Users) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + user.firstName + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        user.statementType = "Delete";
        this.saveUser('Delete');
      }
    });
  }

  hideDialog() {
    this.userDialog = false;
    this.editform = false;
    this.submitted = false;
  }
  checkduplicateemail() {
    console.log(this.users);

    if (this.users.filter((i: any) => i.emailAddress == this.userRegistrationForm.get('emailAddress')?.value).length == 0)
      return false
    else
      return true
  }

  checkmodifiedduplicate() {
    if (this.user.emailAddress == this.userRegistrationForm.get('emailAddress')?.value)
      return false
    else

      return this.checkduplicateemail()

  }

  saveUser(type: string) {

    if (type != 'Delete') {
      if (this.checkmodifiedduplicate()) {
        this.showspinner = false;
        return;
      } else {
        this.submitted = true;
      }
    }
    this.statementType = type;
    if (type == 'Delete') {
      this.userapiData = {

        "Id": 0,
        "FirstName": "",
        "LastName": "",
        "EmailAddress": this.users[0].emailAddress?.toLowerCase(),
        "CompanyName": '',
        "StatementType": this.statementType,
        "IsAdmin": false,
        "IsNotifee": false,
        "ThreeIamId": 0
      }
      this.createupdateusers(this.userapiData)
    }
    else if ( type != 'Delete' && this.userRegistrationForm.valid) {
        this.showspinner = true;
        this.userapiData = {

          "Id": this.statementType == 'Insert' ? 0 : this.user.userDetailsID,
          "FirstName": this.userRegistrationForm.controls['firstName'].value,
          "LastName": this.userRegistrationForm.controls['lastName'].value,
          "EmailAddress": this.userRegistrationForm.controls['emailAddress'].value?.toLowerCase(),
          "CompanyName": this.userRegistrationForm.controls['companyName'].value.companyName,
          "StatementType": this.statementType,
          "IsAdmin": this.userRegistrationForm.controls['isAdmin'].value,
          "IsNotifee": this.userRegistrationForm.controls['isNotifee'].value,
          "ThreeIamId": 0
        }
        this.createupdateusers(this.userapiData);

      }
      else {
        this.showspinner = false;
        return;
      }

  }
  valueChanged(value: any) {
    console.log(value)
    if (value.value.companyName == 'BASF') {
      this.userRegistrationForm.controls['isAdmin'].setValue(false)
      this.userRegistrationForm.controls['isNotifee'].setValue(false)
    }

  }
  createupdateusers(userapiData: any) {
    this.dataService.createUpdateUsers(userapiData).subscribe((data: any) => {
      this.users = data;

      this.userRegistrationForm?.reset();
      console.log(this.users);

      switch (this.statementType) {
        case 'Insert':
          this.responsemessage = 'User Added Successfully'
          break;
        case 'Update':
          this.responsemessage = 'User Updated Successfully'
          break;
        case 'Delete':
          this.responsemessage = 'User Deleted Successfully'
          break;

        default:
          break;
      }
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.responsemessage, life: 3000 });
      this.editform = false;
      this.showspinner = false;
      this.userDialog = false;
      this.user = {};
    });
  }



}
