
<!--Banner-->
<img src="../../../assets/images/CB_banner_01.png"  class="responsive-img"/>
<!--Banner Text-->
<div class="banner-text">
  <p class="welcome-text">Welcome to the</p>
  <h1>Florham Park</h1>
  <p>Conference Center Booking Application.</p>
  <p class="welcome-text">Please <span class="click-here" (click)="SAMLService()">click here</span> to login and book your room.</p>
</div>


<!--<div class="form-wraper" style="width:500px;margin:auto">
    <div class="form-container card">
        <div class="form-header" style="padding:20px;text-align: center;">
            <a class="navbar-brand nav-img p-0 m-0" href="#">
             
            </a>
        </div>
        <div class="form-body" style="padding:20px">
            <div class="flex items-center justify-between" style="text-align : center">
                <button (click)="SAMLService()" class="btn submit-btn">
                    Internal Log in
                </button>
<p></p>
                <button (click)="SAMLExternalService()" class="btn submit-btn">
                    External Log in
                </button>

            </div>
           
        </div>
    </div>
</div>-->
