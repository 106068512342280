import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  
} from '@angular/router';

import { AuthServiceService } from '../services/auth-service.service';

 @Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  routparams: any;
  constructor(private authService: AuthServiceService, private route: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
 
      
     const path = state.url;
     if(path =='/admin')
     return this.checkUseradminLogin();
    else
    return this.checkUserLogin();
  }
  checkUserLogin(): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.route.navigate(['/unauthorized'])
      return false;
    }
  }
  checkUseradminLogin(): boolean {
    if (this.authService.isUserAdmin()) {
      return true;
    } else {
      this.route.navigate(['/unauthorized'])
      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}
