import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isError: boolean | undefined;
  returnUrl: string | undefined;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: DataService,
    private spinner: NgxSpinnerService
  ) { }
  usertoken: any;
  notAuthorized = false;
  isloading = true;
  userData: any = {
    id: 0,
    basf_user_id: '',

    email: '',

    country: [{ id: 0, userId: 0, countryId: 0 }],
    role: [{ id: 0, userId: 0, roleId: 0 }],
  }
 
  _jwtToken: any;


  ngOnInit(): void {
    this.spinner.show();
    
    this.route.queryParams.subscribe((params) => {
       
      console.log(params, params['returnUrl'])
      if (params['returnUrl'] != undefined) {
        sessionStorage.setItem('returnUrl', params['returnUrl']);
      }

     
      const token =params.token;
      let decodedTokenShort = JSON.parse(window.atob(token.split('.')[1]));
      let userobjectShort = JSON.parse(decodedTokenShort.userObject);
      sessionStorage.setItem('token',token);
      sessionStorage.setItem('userobject',JSON.stringify(userobjectShort));
            sessionStorage.setItem('isadmin',userobjectShort.isAdmin);
      sessionStorage.setItem('firstname',userobjectShort.FirstName);
      sessionStorage.setItem('lastname',userobjectShort.LastName);
      sessionStorage.setItem('emailadderess',userobjectShort.EmailAddress);
      console.log(userobjectShort)
      let user = {
        id: userobjectShort.UserDetailsID,
        basf_user_id: userobjectShort.loginUserID ?? '',
        email: userobjectShort.EmailAddress,
        company_name:userobjectShort.CompanyName,
        isadmin:userobjectShort.isAdmin,
        isnotifee:userobjectShort.IsNotifee
      };
      console.log(user, "from params");
sessionStorage.setItem("isAdmin",userobjectShort.isAdmin);
sessionStorage.setItem("usercompany",userobjectShort.CompanyName);
      let userRegistered = JSON.parse(userobjectShort?.IsActive ?? "true");

        localStorage.setItem('token', token);
        this.service.setuser(user);
        if (!userRegistered) {
          this.router.navigate(['/unauthorized'], { state: user });
          return;
        }
        
        sessionStorage.setItem('userloggedin', window.btoa('true'));

        let returnUrl = localStorage.getItem('returnUrl');
        if (returnUrl != undefined) {
          this.router.navigate([returnUrl]);
        } else {
          this.router.navigate(['/home']);
        }
    });
let userloggedin=sessionStorage.getItem('userloggedin');
    if (atob(userloggedin || 'false') != 'false') {
      this.router.navigate(['/home']);
    } 
  }
  
  SAMLService() {
    this.spinner.show();
  
    this.service.getSAMLService().subscribe({
      next: (result: any) => {
        console.log(result);
        this.spinner.hide();
        window.location.href = result.toString();
      },
      error: (error: any) => {
        console.log('error ', error);
        this.spinner.hide();
      },
    });
  }
  SAMLExternalService() {
    this.spinner.show();
  
    this.service.getSAMLLoginExternalService().subscribe({
      next: (result: any) => {
        console.log(result);
        this.spinner.hide();
        window.location.href = result.toString();
      },
      error: (error: any) => {
        console.log('error ', error);
        this.spinner.hide();
      },
    });
  }

  
  // redirectToPage() {
  //   this.spinner.show();
  //   console.log(sessionStorage.getItem('returnUrl'))
  //   if (sessionStorage.getItem('returnUrl') !== null)
  //     this.returnUrl = sessionStorage.getItem('returnUrl') as string;
  //   if (this.returnUrl == undefined || this.returnUrl == '') {
  //     this.router.navigate(['/home']);
  //   }
  //   else {
  //     console.log(this.returnUrl)
  //     sessionStorage.removeItem('returnUrl');
  //     this.returnUrl = decodeURIComponent(this.returnUrl);
  //     this.router.navigateByUrl(this.returnUrl);
  //   }
  //   this.spinner.hide();
  // }
}
