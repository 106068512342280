const BaseUrl = './';

export const environment = {
  production: true,
  getJWTToken: `${BaseUrl}Jwt/GetToken`,
  SAML: `${BaseUrl}Saml/GetSamlURL`,
  SAMLLoginExternal: `${BaseUrl}Saml/GetSamlURLExternal`,
  GetUser: `${BaseUrl}User/GetUser`,
  retrieveJWTToken:`${BaseUrl}JWT/Token`,
  getUsersforLogin:`${BaseUrl}User/GetUser`,
  RetrieveUserDetailsByEmail: `${BaseUrl}User/RetrieveUserDetailsByEmailid`,
  retrieveUserbyLoginName: BaseUrl + `Admin/User/{loginId}/loggedInUser/{userName}`,

  GetMeetingStartEndTime:`${BaseUrl}MeetingRooms/GetMeetingStartEndTime`,
  GetAllmeetingRoom:`${BaseUrl}MeetingRooms/GetAllmeetingRoom`,
  GetAllmeetingRoombyid:`${BaseUrl}MeetingRooms/GetAllmeetingRoom`,
  ManageMeetings:`${BaseUrl}MeetingRooms/ManageMeetings`,
  UpdateMeetings:`${BaseUrl}MeetingRooms/UpdateMeetings`,
  CancelMeetings:`${BaseUrl}MeetingRooms/CancelMeetings`,
  
  GetMeetings:`${BaseUrl}MeetingRooms/GetMeetings`,
  GetCompanys:`${BaseUrl}MeetingRooms/GetCompany`,
  CreateUpdateUsers:`${BaseUrl}User/CreateUpdateUsers`,
  GetUserList: `${BaseUrl}User/GetUserList`,
  createUpdateNotifies: `${BaseUrl}User/createUpdateNotifies`,
  GetNotifiesList: `${BaseUrl}User/GetNotifiesList`,
  GetErrorResponse: `${BaseUrl}User/GetErrorResponse`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
*/