import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';

import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SafeHtml } from '@angular/platform-browser';
import { timer } from 'rxjs';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css'],

  encapsulation: ViewEncapsulation.None,
})
export class MeetingComponent implements OnInit {
  editFormId!: boolean;
  meetingRegistrationForm: any = FormGroup
  submitted!: boolean;
  dropdowntime: any = [];
  dropdownrooms!: any[];
  closetext!: string;
  dropdownendtime: any = [];
  sanitizedHtml!: SafeHtml;
  isadmin: boolean = false;
  showspinner: boolean = false;
  dropdownstarttime: any = [];
  newArr: any = [];
  apimeetingData: any = {};
  formattedDate: any = new Date();
  meetingroomid: any;
  datepipe: DatePipe = new DatePipe('en-US')
  dropdownwithlunchtime: any;
  dropdownendtimewithoutchange: any = [];
  timedropdown: any = [];
  convertedtimedropdowndata: any = [];


  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService, public router: Router,
  ) { }
  get f() { return this.meetingRegistrationForm.controls; }
  ngOnInit(): void {
    this.formattedDate = history.state.startdate;
    this.isadmin = sessionStorage.getItem('isAdmin')?.toLowerCase() != 'false';
    this.meetingRegistrationForm = this.formBuilder.group({
      room: new FormControl('', Validators.required),
      startdate: new FormControl({ disabled: true, value: new Date() }, Validators.required),
      starttime: new FormControl('', Validators.required),
      enddate: new FormControl('', Validators.required),
      endtime: new FormControl('', Validators.required),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      emailAddress: new FormControl(''),
      title: new FormControl('', Validators.required),
      meetingdetails: new FormControl('', Validators.required)
    });
    this.meetingroomid = history.state.conferenceroomid;
    this.getmeetingtimes();



  }
  bindFormData(state: any) {
    if (state.meetingtype == 'New') {
      this.editFormId = false
    }
    else
      this.editFormId = true;

    this.closetext = (state.meetingtype == 'Edit' || state.meetingtype== 'Update') ? 'Close' : 'Cancel';
    console.log(state)


    console.log("stateinfo" + state)
    console.log(state)
    this.meetingroomid = this.meetingRegistrationForm.value.room.meetingRoomID;
    this.formattedDate = this.datepipe.transform(state.startdate, 'yyyy-MM-dd')
    this.meetingRegistrationForm.controls['startdate'].setValue(this.formattedDate);
    this.meetingRegistrationForm.controls['enddate'].setValue(this.formattedDate);

    this.meetingRegistrationForm.controls['room'].setValue(this.dropdownrooms.find((i: any) => i.meetingRoomID == state.conferenceroomid));
    this.meetingRegistrationForm.controls['firstName'].setValue(state.firstName);
    this.meetingRegistrationForm.controls['lastName'].setValue(state.lastName);
    this.meetingRegistrationForm.controls['emailAddress'].setValue(state.emailAddress);
    if (state.meetingtype != 'New') {
      const { htmlToText } = require('html-to-text');

      let constval = htmlToText(state.meetingdetails, {
        wordwrap: 1300
      });
      console.log(constval);
      this.meetingRegistrationForm.controls['title'].setValue(state.title);
      this.meetingRegistrationForm.controls['meetingdetails'].setValue(constval);
    }
    else {
      this.meetingRegistrationForm.controls['title'].setValue('');
      this.meetingRegistrationForm.controls['meetingdetails'].setValue('');
    }


    console.log(this.meetingRegistrationForm.value)

  }

  getmeetingroomdetails() {

      this.dataService.getAllmeetingRoom().subscribe((data: any[]) => {
        if (data.length > 0) {
          this.dropdownrooms = data;

          console.log(this.dropdownrooms);
          this.bindmeetingstartdate(history.state.starttime)
          this.bindmeetingenddate(history.state.endtime)

          this.bindFormData(history.state)
        }
      });
   
  }
  bindmeetingstartdate(timevalue: any) {
    console.log(timevalue);


    let blocktimestartindex = this.convertedtimedropdowndata.findIndex((i: any) => i.startAndEndTime == this.time24To12convert(this.dropdownrooms.filter(i => i.meetingRoomID == this.meetingroomid)[0].blockStartTime))
    let blocktimeendindex = this.convertedtimedropdowndata.findIndex((i: any) => i.startAndEndTime == this.time24To12convert(this.dropdownrooms.filter(i => i.meetingRoomID == this.meetingroomid)[0].blockEndTime))

    if (blocktimestartindex != -1) {
      this.convertedtimedropdowndata.forEach((item: any, index: any) => {
        if (index < blocktimestartindex || index >= blocktimeendindex)
          this.dropdownstarttime.push({
            startAndEndTimeID: item.startAndEndTimeID, startAndEndTime: item.startAndEndTime, startAndEndTime_old: item.startAndEndTime_old, isFreez: item.isFreez, isShow: item.isShow, createdBy: item.createdBy, createdDate: item.createdDate, isActive: item.isActive, modifiedBy: item.modifiedBy, modifiedDate: item.modifiedDate
          })
      })
    }
    else {
      this.dropdownstarttime = this.convertedtimedropdowndata
    }


    console.log(this.dropdownstarttime);
    let strattimeindex: number

    strattimeindex = this.dropdownstarttime.findIndex((i: any) => new Date(new Date(timevalue).toDateString() + ' ' + i.startAndEndTime).toTimeString() >= new Date(timevalue).toTimeString())


    this.meetingRegistrationForm.controls['starttime'].setValue(this.dropdownstarttime[strattimeindex])
    console.log(this.dropdownstarttime[strattimeindex]);
  }
  bindmeetingenddate(timevalue: any) {

    let blocktimestartindex = this.convertedtimedropdowndata.findIndex((i: any) => i.startAndEndTime == this.time24To12convert(this.dropdownrooms.filter(i => i.meetingRoomID == this.meetingroomid)[0].blockStartTime))
    let blocktimeendindex = this.convertedtimedropdowndata.findIndex((i: any) => i.startAndEndTime == this.time24To12convert(this.dropdownrooms.filter(i => i.meetingRoomID == this.meetingroomid)[0].blockEndTime))
    if (blocktimestartindex != -1) {
      this.convertedtimedropdowndata.forEach((item: any, index: any) => {
        if (index <= blocktimestartindex || index > blocktimeendindex)
          this.dropdownendtime.push({
            startAndEndTimeID: item.startAndEndTimeID, startAndEndTime: item.startAndEndTime, startAndEndTime_old: item.startAndEndTime_old, isFreez: item.isFreez, isShow: item.isShow, createdBy: item.createdBy, createdDate: item.createdDate, isActive: item.isActive, modifiedBy: item.modifiedBy, modifiedDate: item.modifiedDate
          })
      })
    }
    else {
      this.dropdownendtime = this.convertedtimedropdowndata
    }

    let endtimeindex: number
    endtimeindex = this.dropdownendtime.findIndex((i: any) => new Date(new Date(timevalue).toDateString() + ' ' + i.startAndEndTime).toTimeString() >= new Date(timevalue).toTimeString())

    if (endtimeindex != 0)
      this.meetingRegistrationForm.controls['endtime'].setValue(this.dropdownendtime[endtimeindex])
    else
      this.meetingRegistrationForm.controls['endtime'].setValue(this.dropdownendtime[1])
    console.log(this.dropdownendtime[endtimeindex]);
    console.log(this.dropdownendtime);
  }

  getmeetingtimes() {
    this.dataService.getHeaders().subscribe((data: any[]) => {
      this.timedropdown = data.filter((i: any) => i.startAndEndTimeID >= 15 && i.startAndEndTimeID <= 41);

      this.timedropdown.forEach((item: any) => {
        console.log(item)


        this.convertedtimedropdowndata.push({
          startAndEndTimeID: item.startAndEndTimeID, startAndEndTime: this.time24To12convert(item.startAndEndTime), startAndEndTime_old: item.startAndEndTime, isFreez: item.isFreez, isShow: item.isShow, createdBy: item.createdBy, createdDate: item.createdDate, isActive: item.isActive, modifiedBy: item.modifiedBy, modifiedDate: item.modifiedDate
        })
      })
      this.dropdownendtimewithoutchange = this.convertedtimedropdowndata;
      this.getmeetingroomdetails();
    }
    )
  }


  time24To12convert(a: any) {
    //below date doesn't matter.
    return (new Date("1955-11-05T" + a + "Z")).toLocaleTimeString("bestfit", {
      timeZone: "UTC",
      hour12: !0,
      hour: "numeric",
      minute: "numeric"
    });
  };



  cancelMeeting(action: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Cancel this meeting' + '?' + '<br/>' + "Room Name : " + history.state.conferenceroomname + '<br/>' + "Start Time : " + history.state.starttime.toLocaleString([], { hour: '2-digit', minute: "2-digit" }) + '<br/>' + "End Time : " + history.state.endtime.toLocaleString([], { hour: '2-digit', minute: "2-digit" }),
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onSubmit('cancel');
      }
    });
  }

  onSubmit(action: string) {
    this.showspinner = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.meetingRegistrationForm.invalid) {
      this.showspinner = false;
      return;
    }

    //True if all the fields are filled
    if (this.submitted && this.validatemeeting()) {
      this.apimeetingData = {

        "meetingCreatorName": sessionStorage.getItem('firstname') + ' ' + sessionStorage.getItem('lastname'),
        "meetingCreatorEmail": sessionStorage.getItem('emailadderess'),
        "meetingRoomID": this.meetingRegistrationForm.value.room.meetingRoomID,
        "meetingID": history.state.meetingid,
        "adminEmail": sessionStorage.getItem('emailadderess'),
        "meetingSubject": this.meetingRegistrationForm.value.title,
        "meetingBody": this.meetingRegistrationForm.value.meetingdetails,
        "meetingStartDateTime": this.meetingRegistrationForm.value.enddate + 'T' + this.meetingRegistrationForm.value.starttime.startAndEndTime_old,
        "meetingEndDateTime": this.meetingRegistrationForm.value.enddate + 'T' + this.meetingRegistrationForm.value.endtime.startAndEndTime_old,
        "Company": sessionStorage.getItem('usercompany'),
        "action": action,
        "meetingroomEmail": this.meetingRegistrationForm.value.room.emailAddress
      }
      if(action=='cancel'){
      this.apimeetingData.meetingStartDateTime = this.meetingRegistrationForm.value.enddate + 'T' + history.state.starttime.toLocaleString([], { hour12: false, hour: '2-digit', minute: "2-digit", second: "2-digit" })
      this.apimeetingData.meetingEndDateTime = this.meetingRegistrationForm.value.enddate + 'T' + history.state.endtime.toLocaleString([], { hour12: false, hour: '2-digit', minute: "2-digit", second: "2-digit" })
      }
      switch (action) {
        case 'create':

          this.dataService.manageMeetings(this.apimeetingData).subscribe({
            next: (data: any) => {
              this.eventsuccess(data);

            },

            error: (error: any) => {

              this.eventerrormessage(error);


            },



          });

          break;

        case 'update':
          this.dataService.updateMeetings(this.apimeetingData).subscribe({

            next: (data: any) => {
              this.eventsuccess(data);

            },

            error: (error: any) => {

              this.eventerrormessage(error);

            }
          }
          );
          break;
        case 'cancel':
       
            this.dataService.cancelMeetings(this.apimeetingData).subscribe({

              next: (data: any) => {

                this.eventsuccess(data);

              },

              error: (error: any) => {
                this.eventerrormessage(error);



              }
            }
            );

          break;
        default:
          break;

      }

    }
    else {
      this.showspinner = false;
      this.messageService.add({ severity: 'error', summary: 'Lunch slot booking not allowed', detail: 'Lunch slot booking not allowed pelase check the timings for the meeting ', life: 3000 });

    }

  }
  
  eventsuccess(data: any) {
    console.log(data);

    this.showspinner = false;

    if (data.toString() == 'Reservation canceled successfully') {

      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Meeting Canceled successfully', life: 3000 });
      timer(1500)
        .subscribe(i => {
          this.router.navigate(['/home']);
        })


    }


    if (data.toString() == 'Reserved successfully') {

      this.messageService.add({ severity: 'success', summary: 'Successful', detail: data.toString(), life: 3000 });
      timer(1500)
        .subscribe(i => {
          this.router.navigate(['/home']);
        })

    }

  }
  eventerrormessage(error: any) {
    this.showspinner = false;
    console.log(error);
    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error, life: 3000 });


  }
  validatemeeting() {

    console.log(this.meetingRegistrationForm)
    this.meetingroomid = this.meetingRegistrationForm.value.room.meetingRoomID;
    let blockStartTime = this.dropdownrooms.filter(i => i.meetingRoomID == this.meetingroomid)[0].blockStartTime
    let blockEndTime = this.dropdownrooms.filter(i => i.meetingRoomID == this.meetingroomid)[0].blockEndTime
    if (blockStartTime != null) {
      let blockStartTime = this.dropdownrooms.filter(i => i.meetingRoomID == this.meetingroomid)[0].blockStartTime
      return !this.isTimeBetween(this.meetingRegistrationForm.value.starttime.startAndEndTime_old, this.meetingRegistrationForm.value.endtime.startAndEndTime_old, blockStartTime)
        || !this.isTimeBetween(this.meetingRegistrationForm.value.starttime.startAndEndTime_old, this.meetingRegistrationForm.value.endtime.startAndEndTime_old, blockEndTime)
    }
    else {
      return true;
    }


  }

  isTimeBetween(startTime: string, endTime: string, targetTime: string): boolean {
    const start = new Date(`2023-10-08T${startTime}`);
    const end = new Date(`2023-10-08T${endTime}`)

    const target = new Date(`2023-10-08T${targetTime}`);
    return start <= target && target <= end;

  }
  valueChanged(value: any) {

    switch (value?.name) {
      case 'starttime':
        console.log(value.name);
        if (new Date(new Date().toDateString() + ' ' + this.meetingRegistrationForm.value.endtime.startAndEndTime_old).getTime() <= new Date(new Date().toDateString() + ' ' + value.value.startAndEndTime_old).getTime()) {
          this.dropdownendtime = this.dropdownendtimewithoutchange.filter((i: any) => i.startAndEndTimeID > value.value.startAndEndTimeID);
          this.meetingRegistrationForm.controls['endtime'].setValue(this.dropdownendtime[0])

        }
        break;
      case 'endtime':
        console.log(value?.value)
        if (new Date(new Date().toDateString() + ' ' + this.meetingRegistrationForm.value.starttime.startAndEndTime_old).getTime() >= new Date(new Date().toDateString() + ' ' + value.value.startAndEndTime_old).getTime()) {
          this.meetingRegistrationForm.controls['endtime'].setValue(this.dropdownendtime[1]);
          this.meetingRegistrationForm.controls['starttime'].setValue(this.dropdownstarttime[0])
        }
        break;
      case 'room':
        this.meetingroomid = value?.value?.meetingRoomID;
        this.bindmeetingstartdate('');
        this.bindmeetingenddate('')
        break;
      default:
        break;

    }


  }


  adminClick() {
    this.router.navigate(['/admin'])
  }
}

