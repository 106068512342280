<div class="side-spacing" style="min-height: 100vh;">
  <p-toast></p-toast>
    <div class="row header-div">
      <div class="col col-md-10 header-label"  >
      </div>
      <div class="col col-md-2" style="text-align: end;margin-top: 85px;">
        <p-button *ngIf="isadmin" label="Admin"  styleClass="p-button-admin btnadmin"  label="Admin" icon="pi pi-user" [routerLink]="['/admin']" ></p-button>
      </div>
    </div>
    <div style="display: flex; justify-content: flex-end; padding-right: inherit;" class="note-text"><span >Choose Date</span></div>
           
    <app-calendar  [meetingdate]="dateValue" ></app-calendar>
    </div>