import { Component, EventEmitter, Input, Output, Self, ViewEncapsulation, } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-dynamic-form-fields',
  templateUrl: './dynamic-form-fields.component.html',
  styleUrls: ['./dynamic-form-fields.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormFieldsComponent {
  @Input() placeholder: string = '';
  @Input() fieldType: string = ''
  @Input() options!: any[];
  @Input() inputType: string = '';
  @Input() minDate: string = '';
  @Input() maxDate: string = '';
  @Input() fieldDisable: any = false
  @Input() fieldreadOnly: any = false
  @Input() optionLabel: string = ''
  @Input() radioValue2: any
  @Input() radioValue1: any
  @Input() checkBoxMessage: string = ''
  @Input() startAddOn: string = ''
  @Input() endAddOn: string = ''
  @Input() searchable: boolean = false
  @Input() editable: boolean = false
  @Input() width: string = '100%'
  @Input() radioName: string = ''
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() blurEditable: EventEmitter<any> = new EventEmitter<any>();
  disabled!: boolean;
  readOnly!:boolean
  onChange = (value: any) => { this.blur.emit({ 'value': value, 'name': this.controlDir.name }) };
  onTouched: () => void = () => { };

  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  writeValue(value: any): void {
    value && value != this.controlDir.control?.value && this.controlDir.control?.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  validate(value: any) {
    console.log(value, "validate", this.controlDir.name, this.controlDir.value)
    this.onChange(value)
    this.onTouched()
    this.blur.emit({ 'value': value, 'name': this.controlDir.name })
  }

  filteredOptions: any[] = []
  filterOptions(event: any) {
    let filtered: any[] = [];
    let query = event.query;
    this.options.forEach(element => {
      let option = element;
      if (option.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(option);
      }
    })
    this.filteredOptions = filtered
  }

}
