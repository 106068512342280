import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'hasRequiredField'
})
export class HasRequiredFieldPipe implements PipeTransform {

  transform(control: AbstractControl | null): boolean {
    if (!control?.validator)
      return false;

    const validator = control.validator({} as AbstractControl)
    return validator && validator['required']
  }

}
