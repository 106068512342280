
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'src/app/customer';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent {
  @Input() meetingdate: any;

  customers!: Customer[];

  tooltipOptions = {
    showDelay: 150,
    autoHide: false,
    tooltipEvent: 'hover',
    tooltipPosition: 'left',
    hide: true
  };
  dialogVisible!: boolean;

  scrollableCols!: any[];

  frozenCols!: any[];

  unlockedCustomers!: any[];

  lockedCustomers!: any[];

  balanceFrozen: boolean = false;

  rowGroupMetadata: any;
  colwidth: any;
  Cols!: any[];
  conferenceroom!: any[];
  isadmin: boolean = false;
  selectedData!: any;
  updateCols: any;
  addedCols!: any[];
  bookingsdata!: any[];
  calcuatedstring: string = '';
  meetingtitle: any;
  meetingbody: any;
  leftpadding: any = 0;
  hideborder: number = 1;
  previousEvent: any;
  diffcount: number = 0;
  dateValue: Date = new Date();

  meetingstarttime: any;
  meetingendtime: any;
  date = new Date().toDateString();
  meetingtooltip: any;
  meetingtype: string = "";
  firstname: any;
  lastname: any;
  emailadderess: any;
  apimeetingData: any = {};
  dropdownrooms!: any[];
  conferenceroomdata: any = [];
  meetingrooms: string = '';
  meetingid: string = '';
  roomsstring: string = '';
  previousmeetingroom: any;
  previousrowindex: number = 0;
  lunchtime: boolean = false;
  reservedby: string = '';
  companyname: string = '';
  showspinner: boolean = true;
  updatedslots: any = [];
  timecols!: any[];
  islunch: boolean = false;
  showmeeting: boolean = false;
  notetext: boolean = false;
  constructor(private dataService: DataService, private router: Router) { }

  ngOnInit() {
    console.log(this.router.url)
    if(this.router.url=='/home'){
      this.notetext=true
    }

    if (this.meetingdate != null || this.meetingdate != undefined) {
      this.dateValue = this.meetingdate;
    }
    this.dataService.getHeaders().subscribe((data: any[]) => {
      this.Cols = data;

      this.updateCols =[...this.Cols].reverse()
      this.addedCols = this.updateCols.push({
        startAndEndTimeID: 0, startAndEndTime: "Rooms", isFreez: true, isShow: true, isActive: true, createdBy: "balasun4", createdDate: "2023-08-17T10:37:31.16",
        modifiedBy: "balasun4", modifiedDate: "2023-08-17T10:37:31.16",
      });
      this.Cols = this.updateCols.reverse();
      this.Cols = this.Cols.filter(i => i.isShow);
      console.log(this.Cols);
      this.timecols = data;
      this.isadmin = Boolean(sessionStorage.getItem('isadmin'));
      this.showspinner = true;
      this.getmeetingroomdetails();


    });





  }



  loadMeetings(date: string, meetingroom: string) {
    this.apimeetingData = {

      "meetingCreatorName": "",
      "meetingCreatorEmail": "",
      "meetingRoomID": "",
      "meetingID": "",
      "adminEmail": "",
      "meetingSubject": "",
      "meetingBody": "",
      "meetingStartDateTime": date,
      "meetingEndDateTime": date,
      "company": "",
      "action": "get"


    }


    this.dataService.getMeetings(this.meetingrooms, formatDate(new Date(date), 'yyyy-MM-dd', 'en-US')).subscribe((data: any[]) => {
      this.bookingsdata = data
      console.log(this.bookingsdata);

      console.log(this.dropdownrooms);
      this.conferenceroomdata = [];
      this.dropdownrooms.forEach(element => {

        this.updatedslots = [];
        if (this.bookingsdata.find((i: any) => i.meetingroomid.toLowerCase() == element.emailAddress.toLowerCase())?.slots != undefined) {
          this.updatedslots = this.bookingsdata.find((i: any) => i.meetingroomid.toLowerCase() == element.emailAddress.toLowerCase())?.slots;
        }
        if (this.updatedslots.length > 0) {
          this.updatedslots.forEach((slotselement: any) => {
            if (new Date(new Date(slotselement.starttime).toDateString() + ' ' + '00:00:00') < new Date(this.dateValue.toDateString() + ' ' + '00:00:00')) {

              slotselement.starttime = new Date(slotselement.starttime).toDateString() + ' ' + '07:00:00';
              slotselement.starttimeold = slotselement.starttime


            }
            else {


              slotselement.starttimeold = slotselement.starttime
            }
            console.log(this.updatedslots);
          });
        }
        else {
          this.updatedslots = []
        }



        this.conferenceroomdata.push({ meetingroomid: element.emailAddress, meetingroomname: element.meetingRoomName + ' ' + element.minMaxCount, blockStartTime: element.blockStartTime, blockEndTime: element.blockEndTime, slots: this.updatedslots })
      });


      this.conferenceroomdata.forEach((element:any) => {
        if(element.blockStartTime != null){
          let utcDate = moment.utc(new Date(this.meetingdate));
        
          element.slots.push({body:'Lunch Time',company:'local',endtime:utcDate.format('YYYY-MM-DD')+'T'+element.blockEndTime,id:'',isbookedfromapp:false,meetingcreateddate:"2023-10-12T01:51:59.5369777Z",
meetingmodifieddate: "2023-10-12T01:52:06.8082887Z",meetingorganizerEmail:"admin@basf.com",meetingorganizername:"admin Conferencebooking",starttime: utcDate.format('YYYY-MM-DD')+'T'+element.blockStartTime,starttimeold:utcDate.format('YYYY-MM-DD')+'T'+element.blockStartTime,subject:"Lunch Time"

          })
        }
      });
      console.log(this.conferenceroomdata)
      this.showspinner = false;
    });

  }

  onDateChange(dateValue: Date) {
    this.showspinner = true;
    this.loadMeetings(dateValue.toDateString(), this.meetingrooms);
  }
  decrementDate() {
    this.showspinner = true;
    const currentDate = new Date(this.dateValue);

    currentDate.setDate(currentDate.getDate() - 1);

    this.dateValue = currentDate;
    this.loadMeetings(this.dateValue.toDateString(), this.meetingrooms);
  }



  incrementDate() {
    this.showspinner = true;
    const currentDate = new Date(this.dateValue);

    currentDate.setDate(currentDate.getDate() + 1);

    this.dateValue = currentDate;
    this.loadMeetings(this.dateValue.toDateString(), this.meetingrooms)
  }

  getmeetingroomdetails() {

      this.dataService.getAllmeetingRoom().subscribe((data: any[]) => {
        if (data.length > 0) {
          this.dropdownrooms = data;
          console.log(this.dropdownrooms);

          this.dropdownrooms.forEach((element: any) => {

            this.roomsstring = this.roomsstring + element.emailAddress + ','
          });
          let lastChar = this.roomsstring.slice(-1);
          if (lastChar == ',') {
            this.roomsstring = this.roomsstring.slice(0, -1);
          }
          console.log(this.roomsstring.trimEnd());
          //.filter(i=>i.field !='id' && i.field !='conferenceroomname' ); 
          console.log(this.dropdownrooms);
          this.meetingrooms = this.roomsstring;
          this.loadMeetings(this.meetingdate, this.meetingrooms);
          console.log(this.bookingsdata);

        }
      });
   
  }

  getStyles() {
    return 'padding-left: 20px; position: absolute;z-index: 1;';
  }
  getborderstyle(col: any, i: number) {
    if (col.startAndEndTime != 'Rooms' && i % 2 == 0)
      return 0
    else
      return
  }

  checkdata(rowData: any, col: any, i: number, columns: any) {
   return this.bindmeetingdata(rowData, col, i, columns);

  }


  bindmeetingdata(rowData: any, col: any, i: number, columns: any) {
    this.hideborder = 1
 
    let starttime: any = new Date(this.dateValue.toDateString() + ' ' + col.startAndEndTime)
    let endtime: any = new Date(this.dateValue.toDateString() + ' ' + this.Cols.filter((i: any) => i.startAndEndTimeID > col.startAndEndTimeID)[0]?.startAndEndTime)
    let rowlist = rowData['slots'].filter((i: any) => new Date(new Date(starttime).toDateString() + ' ' + new Date(i.starttime).toLocaleTimeString()) >= new Date(starttime));
     if(rowlist.length>0)
    {
     let selectedrowlist=rowlist.filter((i:any)=>new Date(new Date(starttime).toDateString() + ' ' + new Date(i.starttime).toLocaleTimeString())>= starttime && new Date(new Date(starttime).toDateString() + ' ' + new Date(i.starttime).toLocaleTimeString())< endtime)
 return this.validateBindMeetingData(starttime,endtime,selectedrowlist,rowlist,rowData,col);
    }
    else {
      if (this.diffcount > 2) {
        this.diffcount--;
        this.hideborder = 0;
      }
      return false
    }
  }


  validateBindMeetingData(starttime: any,endtime: any,selectedrowlist: any,rowlist: any,rowData: any,col: any ){
    if(selectedrowlist.length>0){
      this.meetingtitle = selectedrowlist[0].subject;
      this.companyname =  this.bindMeetingCompany(selectedrowlist)
      this.reservedby  = this.bindResrvedbyDetails(this.companyname,selectedrowlist);
     
      this.meetingbody = selectedrowlist[0].body;

      if (new Date(starttime.toDateString()) <= new Date(new Date(selectedrowlist[0].starttime).toDateString())) {
        starttime = new Date(new Date(selectedrowlist[0].starttime).toDateString() + ' ' + col.startAndEndTime);
      
      //  starttime = starttime
      }
      // else {
      //   starttime = new Date(new Date(selectedrowlist[0].starttime).toDateString() + ' ' + col.startAndEndTime);
      // }
      this.meetingstarttime = starttime;
      this.meetingendtime = selectedrowlist[0].endtime;
      this.meetingtooltip = "Reserved by: " + this.companyname + " Start Time : " + this.meetingstarttime.toLocaleString() + " End Time : " + new Date(this.meetingendtime).toLocaleString()
      this.leftpadding = 0;

      let starttimecal: any = new Date(starttime.toDateString() + ' ' + new Date(selectedrowlist[0].starttime).toLocaleTimeString()).getTime();
      let endtimecal: any = new Date(starttime.toDateString() + ' ' + new Date(selectedrowlist[0].endtime).toLocaleTimeString()).getTime();
      if (new Date(selectedrowlist[0].endtime).getTime() >= new Date(this.dateValue.toDateString() + ' ' + '20:00:00').getTime()) {
        endtimecal = new Date(this.dateValue.toDateString() + ' ' + '20:00:00').getTime();
      }


      let diffMs = endtimecal - starttimecal;
      let diffMins = Math.round(diffMs / 60000); // minutes
      this.diffcount = (diffMins / 30);
      this.colwidth = (diffMins / 15);
      this.previousEvent = rowlist[0];
      this.hideborder = 0;
      if (this.colwidth == 50)
        this.colwidth = 50
      else {
        this.colwidth = this.colwidth * 41.3
      }

      if (diffMins / 15 <= 1) {
        this.leftpadding = diffMins * 100 / 30;
      }
      else {
        this.leftpadding = 0;
      }
      return true
    }
    else{

      let rowlist =rowData['slots'].filter((i:any) => new Date(new Date(starttime).toDateString() + ' ' + new Date(i.starttime).toLocaleTimeString()) <= new Date(starttime) && new Date(new Date(starttime).toDateString() + ' ' + new Date(i.endtime).toLocaleTimeString()) >= new Date(endtime))
    if(rowlist.length>0)
       this.hideborder = 0;
     
    return false 
    }
  }
  bindResrvedbyDetails(companyname: string, selectedrowlist: any): string {
    if(selectedrowlist[0].company == 'local'){
      this.reservedby = "Reserved for Lunch by BASF";        
    }
    else{
    this.reservedby = "Reserved by " + this.companyname;
    }
    return this.reservedby;
  }
  bindMeetingCompany(selectedrowlist: any){
    this.companyname = '';
    if (selectedrowlist[0].company == null || selectedrowlist[0].company == 'local' ) {
      this.companyname = 'BASF';
    }
    else {
      this.companyname = selectedrowlist[0].company
    }
    return this.companyname
  }
  getpadding() {
    return this.leftpadding;
  }
  getWidth(value: string) {
    if (value == 'Conference Room')
      return 200;
    else
      return 50
  }

  showDialog() {
    this.dialogVisible = true;
  }



  
  isTimeBetween(startTime: string, endTime: string, targetTime: string): boolean {
    const start = new Date(`2023-10-08T${startTime}`);
    const end = new Date(`2023-10-08T${endTime}`)

    const target = new Date(`2023-10-08T${targetTime}`);
    return start <= target && target < end;

  }

  onRowDblClick(event: any, rowData: any) {
    this.selectedData = {};
    console.log(event.currentTarget.id);
    console.log(rowData.meetingroomid);
    console.log(rowData.meetingroomname);
    this.islunch = this.isTimeBetween(rowData.blockStartTime, rowData.blockEndTime, event.currentTarget.id);
if (!this.islunch) {

      let starttime: any = new Date(this.dateValue.toDateString() + ' ' + event.currentTarget.id)
      let endtime: any = new Date(starttime.getTime() + 30 * 60000)

      let rowlist = rowData['slots'].filter((i: any) => new Date(i.starttime) <= new Date(this.dateValue.toDateString() + ' ' + event.currentTarget.id) && new Date(i.endtime) > new Date(this.dateValue.toDateString() + ' ' + event.currentTarget.id));
this.onRowDblClickBindData(rowlist,starttime,endtime);

      
      this.selectedData = {
        meetingtype: this.meetingtype,
        meetingid: this.meetingid,
        startdate: this.dateValue,
        starttime: new Date(this.meetingstarttime),
        endtime: new Date(this.meetingendtime),
        conferenceroomid: rowData.meetingroomid,
        conferenceroomname: rowData.meetingroomname,
        firstName: this.firstname,
        lastName: this.lastname,
        emailAddress: this.emailadderess,
        title: this.meetingtitle,
        meetingdetails: this.meetingbody

      };
      if (this.meetingtype == 'New' || (this.meetingtype == 'Update' && rowlist[0]?.isbookedfromapp)) {
        this.router.navigateByUrl('/meeting', { state: this.selectedData });
      }

      else
        return;
    }
    else {
      return;
    }
  }
  

  onRowDblClickBindData(rowlist: any,starttime : any,endtime:any){
    if (rowlist.length > 0) {
      this.meetingtitle = rowlist[0].subject;
      let companyname = '';

      companyname= this.onRowDblClickCompanyBinding(rowlist)
      
      this.reservedby = "Reserved by " + companyname;

      this.meetingbody = rowlist[0].body;

      if (this.getDiffDays(this.dateValue, rowlist[0].starttime) < 1) {
        this.meetingstarttime = new Date(new Date(this.dateValue).toDateString() + ' ' + new Date(rowlist[0].starttime).toLocaleTimeString());
      }
      else {
        this.meetingstarttime = rowlist[0].starttime;
      }
      this.meetingendtime = rowlist[0].endtime;
      


      if (rowlist[0].company == sessionStorage.getItem('usercompany')) {
        this.meetingtype = 'Update'
        this.firstname = rowlist[0].meetingorganizername == null || undefined ? sessionStorage.getItem('firstname') : rowlist[0].meetingorganizername.split(' ')[0]
          this.lastname = rowlist[0].meetingorganizername.split(' ')[1]
          this.emailadderess = rowlist[0].meetingorganizerEmail == null || undefined ? sessionStorage.getItem('emailadderess') : rowlist[0].meetingorganizerEmail
          this.meetingid = rowlist[0].id
      }
    }
    else {
      this.meetingbody = "";
      this.meetingstarttime = starttime;
      this.meetingendtime = endtime;
      this.meetingtype = 'New';
      this.firstname = sessionStorage.getItem('firstname');
      this.lastname = sessionStorage.getItem('lastname');
      this.emailadderess = sessionStorage.getItem('emailadderess');
      this.meetingid = ""
    }

  }
  onRowDblClickCompanyBinding(rowlist: any): string {
    let companyname = '';

    if (rowlist[0].company == null) {
      companyname = 'BASF'
    }
    else {
      companyname = rowlist[0].company
    }
    return companyname;
  }
  getDiffDays(sDate: any, eDate: any) {
    let startDate = new Date(sDate);
    let endDate = new Date(eDate);

    let Time = endDate.getTime() - startDate.getTime();
    return Time / (1000 * 3600 * 24);
  }
}

