import { Component } from '@angular/core';
import { error } from 'console';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent {
  constructor(private dataService: DataService) { }
  ngOnInit() {
    this.dataService.getErrorResponse().subscribe((data: any) => {
    });
  }
}
