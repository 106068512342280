import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Users } from '../interface/users';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private _http: HttpClient) { } 
  private _user: BehaviorSubject<any> = new BehaviorSubject(null);

  public setuser(value: any) {
    this._user.next(value);
  }
  public getuser(): Observable<any> {
    return this._user.asObservable();
  }
  userData = new BehaviorSubject({
    
    createdBy:'',
createdDate:"",
emailAddress:'',
firstName:'',
isActive:true,
isadmin:true,
lastName:'',
loginUserID:'',
modifiedBy:'',
modifiedDate:'',
userDetailsID:0,
userType:''
  });
  sendUserData(value: any) {
    this.userData.next(value);
  }
  getUserData(): Observable<any> {
    return this.userData.asObservable();
  }

  getSAMLService() {
    return this._http.get<any>(
      `${environment.SAML}`
    );
   }
  getSAMLLoginExternalService() {
    return this._http.get<any>(
      `${environment.SAMLLoginExternal}`
    );
   }

  getConferenceBooking() {
    return this._http.get<any>("./assets/conferencebooking.json")
  }
  
  getHeaders() {
    return this._http.get<any>(
      `${environment.GetMeetingStartEndTime}`
    );
  }

  getCompanyDetails() {
    return this._http.get<any>(
      `${environment.GetCompanys}`
    );
  }
  getAllmeetingRoom() {
   return this._http.get<any>(
      `${environment.GetAllmeetingRoom}/all`
    );
  }

  getAllmeetingRoombyid(id: string) {
    return this._http.get<any>(
      `${environment.GetAllmeetingRoombyid}/id`
    );
  }

getConferenceRooms() {
  return this._http.get<any>('assets/rooms.json')
  
}
getBookings() {
  return this._http.get<any>("./assets/bookings.json")
}


getUsers() {
  return this._http.get<any>('assets/users.json')
  
}
  createUpdateUsers(users: Users) {
    return this._http.post<any>(environment.CreateUpdateUsers, users);
  }

  getUserList() {
    return this._http.get<any>(
      `${environment.GetUserList}`
    );
  }
  getErrorResponse() {
    return this._http.get<any>(
      `${environment.GetErrorResponse}`
    );
  }
  
  getMeetings(roomids: string,meetingDate: string) {
    return this._http.get<any>(
      `${environment.GetMeetings}?meetingDetails=${roomids}&meetingDate=${meetingDate}`
   

    );
  }
  
  manageMeetings(data: any) {
    return this._http.post<any>(environment.ManageMeetings, data);
  }

  updateMeetings(data: any) {
    return this._http.post<any>(environment.UpdateMeetings, data);
  }

  cancelMeetings(data: any) {
    return this._http.post<any>(environment.CancelMeetings, data);
  }

  

  RetrieveJWtToken(user: any) {
    return this._http.post<any>(environment.getJWTToken, user);
  }
  getLoggedinUserData(email: string) {
    return this._http.get<any>(
      `${environment.RetrieveUserDetailsByEmail}?emailId=${email}`
    );
  }

}
