import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent {
  isadmin : boolean = false;
  
  dateValue: Date = new Date();
  
  constructor() {
    console.log(this.dateValue);
   }

  ngOnInit() {
    if(sessionStorage.isAdmin != undefined)
    this.isadmin =  JSON.parse(sessionStorage.isAdmin)
  }
  
}

