<div class="side-spacing" style="min-height: 100vh;">
  <p-toast></p-toast>
  <div class="progress-spinner" *ngIf="showspinner"  >
    <p-progressSpinner [style]="{width: '100px', height: '100px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"> </p-progressSpinner>
    <span class="progresstext">Updating conference room booking </span>
  </div>
  <div class="row header-div">
    <div class="col col-md-10 header-label"  >
       Book a Conference Room
  
    </div>
    <div class="col col-md-2" style="text-align: end;">
  
      <p-button *ngIf="isadmin"  label="Admin"  styleClass="p-button-admin btnadmin"   label="Admin" icon="pi pi-user"  (click)="adminClick()"   ></p-button>
    
    </div>
  
  </div>
   
     <div class="row">
        <div class ="col-md-12 sub-header-label">
            <label *ngIf="!editFormId" style="cursor: pointer;">New Meeting</label>
            <label *ngIf="editFormId" style="cursor: pointer;">Edit Meeting</label>
        </div>
    <br/>
    </div>
    <div class="banner3">
        <div class="py-5 banner" >
          <div class="container">
              <form [formGroup]="meetingRegistrationForm" >
               
              <div class="row">
                <div class="col-md-12 p-0">
                  <div  style="align-items: inherit;font-weight: bold;">
                    <p><span> Note: All items with</span><span style="color:red"> * </span><span>are mandatory fields.</span></p>
                  </div>
                  <div class="row" style="align-items: inherit">
                    <p><span style="color:red">*</span> Currently we have option to create Conference booking for single day. Check availability in calendar below.</p>
           
                  </div>
                <div class="row" style="align-items: inherit">
                    <div class="col-md-7 cust-form-group" style="padding-bottom:20px">
                      <div class="form-group">
                        <label class="cust-form-label"  for="room">Rooms
                          <span  class="asterikClass">*</span>
                        </label>

                        <app-dynamic-form-fields formControlName="room" fieldType="dropdown"
                        placeholder="Conference Rooms" optionLabel="meetingRoomName" width="100%" 
                        [options]="dropdownrooms" (blur)="valueChanged($event)">
                    </app-dynamic-form-fields>
                 
                      </div>
                      <div *ngIf="
                          meetingRegistrationForm.get('room')?.invalid &&
                          meetingRegistrationForm.get('room')?.touched
                        " class="text-danger">
                        <div *ngIf="
                            meetingRegistrationForm.get('room')?.hasError('required')
                          ">
Meeting Room is not selected
                        </div>
                      </div>
                    </div>

                    <div  style="display: flex;" >
                      <div class="form-group" >
                        <label class="cust-form-label" for="startdate">Start Date 
                            <span  class="asterikClass">*</span></label>
                            <app-dynamic-form-fields formControlName="startdate" width="100%" fieldType="inputDateReadonly" value="startdate"
                    (blur)="valueChanged($event)" >
                </app-dynamic-form-fields>
            </div>

           
    <div class="form-group"  >
      <label class="cust-form-label" for="starttime">Start Time <span
          class="asterikClass">*</span></label>
          <app-dynamic-form-fields formControlName="starttime" fieldType="dropdown" [editable]="false" optionLabel="startAndEndTime" [options]="dropdownstarttime" 
  (blur)="valueChanged($event)" style="    width: 130px;  display: block;">
</app-dynamic-form-fields>

</div>

    <div class="form-group" style="padding-top: 30px;">
      <label class="cust-form-label" >To</label>
         
</div>

<div class="form-group" >
  <label class="cust-form-label" for="enddate">End Date 
      <span  class="asterikClass">*</span></label>
      <app-dynamic-form-fields formControlName="enddate" width="100%" fieldType="inputDateReadonly" value="enddate"
(blur)="valueChanged($event)">
</app-dynamic-form-fields>
</div>

    <div class="form-group">
      <label class="cust-form-label" for="endtime">End Time <span
          class="asterikClass">*</span></label>
          <app-dynamic-form-fields formControlName="endtime" [editable]="false" fieldType="dropdown" optionLabel="startAndEndTime" [options]="dropdownendtime" 
  (blur)="valueChanged($event)" style="    width: 130px;  display: block;">
</app-dynamic-form-fields>
</div>

</div>
   
            
                   <div class="row">
                    <div class="col-md-2 cust-form-group textformgroup">
                        <div class="form-group formgrouppadding">
                          <p class="cust-form-label cstlbl"  >First Name</p>
                           
              </div>
                       
                      </div>
                      <div class="col-md-6 cust-form-group">
                        <div class="form-group ">
                         
                              <app-dynamic-form-fields formControlName="firstName" [disabled]=true  fieldType="inputTextlabel" value="firstName"
                      >
                  </app-dynamic-form-fields>
              </div>
                      
                      </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2 cust-form-group textformgroup">
                            <div class="form-group formgrouppadding">
                              <p class="cust-form-label cstlbl">Last Name</p>
                               
                  </div>
                           
                          </div>
                          <div class="col-md-6 cust-form-group">
                            <div class="form-group">
                             
                                <app-dynamic-form-fields formControlName="lastName" [disabled]=true  fieldType="inputTextlabel" value="lastName"
                                >
                            </app-dynamic-form-fields>
                  </div>
                            
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 cust-form-group textformgroup">
                                <div class="form-group formgrouppadding">
                                  <p class="cust-form-label cstlbl"  >Email Id</p>
                                   
                      </div>
                               
                              </div>
                              <div class="col-md-6 cust-form-group">
                                <div class="form-group">
                                 
                                    <app-dynamic-form-fields formControlName="emailAddress" [disabled]=true  fieldType="inputTextlabel" value="emailAddress"
                                    >
                                </app-dynamic-form-fields>
                      </div>
                             
                              </div>
                            </div>
                            <div class="row" >
                    <div class="col-md-12 cust-form-group">
                      <div class="form-group">
                        <label class="cust-form-label" style="margin: revert;"  for="title">Title
                          <span class="asterikClass">*</span>
                        </label>
                        <app-dynamic-form-fields formControlName="title" width="100%"  fieldType="inputText"
                        placeholder="title" (blur)="valueChanged($event)">
                    </app-dynamic-form-fields>                      </div>
                      <div *ngIf="
                          meetingRegistrationForm.get('title')?.invalid &&
                          meetingRegistrationForm.get('title')?.touched
                        " class="text-danger">
                        <div *ngIf="
                            meetingRegistrationForm.get('title')?.hasError('required')
                          ">
                          Title Required
                        </div>
                       
                      </div>
                    </div>
                    </div>
                    <div class="row" style="margin-top: 15px;">
                    <div class="col-md-12 cust-form-group">
                        <div class="form-group">
                          <label class="cust-form-label" style="margin: revert;"  for="meetingdetails">Meeting Details
                            <span class="asterikClass">*</span>
                          </label>
                          <app-dynamic-form-fields formControlName="meetingdetails" width="100%"  fieldType="textarea"
                          placeholder="meetingdetails"  (blur)="valueChanged($event)">
                      </app-dynamic-form-fields>                      </div>
                        <div *ngIf="
                            meetingRegistrationForm.get('meetingdetails')?.invalid &&
                            meetingRegistrationForm.get('meetingdetails')?.touched
                          " class="text-danger">
                          <div *ngIf="
                              meetingRegistrationForm.get('meetingdetails')?.hasError('required')
                            ">
                            Meeting Details Required
                          </div>
                         
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 15px;">
                      <div class="col-md-12">
                          <div class="row" style="font-weight: bold;">Please submit the following if required for your meeting: </div>
                          <div class="row">1. Facilities request for your furniture layout and other facility needs.</div>
                          <div class="row">2. Catering request for food/drink services.</div>
                          <div class="row">3. AV support</div>
                          </div>
                    </div>
                    <div class="flex" style="margin-top: 40px;">
                        <div class="row">
                        <div class="col col-md-1" style="width: 200px;">
                        <p-button  label={{closetext}} styleClass="p-button-secondary btnstyleclass" style="opacity: 60%;"  [routerLink]="['/home']"></p-button>
                    </div>
                    <div class="col col-md-1" style="width: 200px;" *ngIf="!editFormId">
                        <p-button  label=" Book"  (click)="onSubmit('create')" [disabled]="meetingRegistrationForm.invalid" styleClass="p-button-submit btnstyleclass" ></p-button>
                    </div>
                    <div class="col col-md-1" style="width: 200px;" *ngIf="editFormId">

                        <p-button   label="Cancel Meeting" (click)="cancelMeeting('cancel')"  styleClass="p-button-cancel btnstyleclass"></p-button>
                    </div>
                    <div class="col col-md-1" style="width: 200px;" *ngIf="editFormId">
                        <p-button label="Update Meeting" (click)="onSubmit('update')"  styleClass="p-button-submit btnstyleclass"></p-button>
                    </div>
                </div>
                    </div>
        
                 
        
                  </div>
                  </div>
              </div>
              </form>
          </div>
        </div>
      </div>

</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<div class="side-spacing" style="min-height: 100vh;">
<app-calendar [meetingdate]="formattedDate" ></app-calendar>
</div>