import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MeetingComponent } from './components/meeting/meeting.component';
import { UserregistrationComponent } from './components/admin/userregistration/userregistration.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'login', pathMatch: 'full', component: LoginComponent },
  { path: 'unauthorized', pathMatch: 'full', component: UnauthorizedComponent },
  { path: 'home', pathMatch: 'full', component: HomeComponent,canActivate: [AuthGuard] },
  {path:'meeting',pathMatch:'full',component:MeetingComponent,canActivate: [AuthGuard]},
  {path:'admin',pathMatch:'full',component:UserregistrationComponent,canActivate: [AuthGuard]},
  {path: '**',pathMatch: 'full', component: UnauthorizedComponent},///If path doesn't match anything reroute to /authentication/signin
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
