<div class="side-spacing" style="min-height: 100vh;">
  
<p-toast></p-toast>

<div class="pb-2 pt-2" style="display: flex; justify-content: flex-end;margin-top: 80px;" >
  <div class="col-md-6 " >
    <button pButton pRipple label="Back" icon="pi pi-angle-left" class="p-button user-back-button" [routerLink]="['/home']"></button>
     </div>
   
       <div class="col-md-6"  style="display: flex; justify-content: flex-end" >
        <button pButton pRipple label="Add User" icon="pi pi-plus" class="p-button p-mr-2" (click)="openNew()"></button>
 
     </div>
     </div>
<div class="card">
    <p-table #dt [value]="users" [columns]="Cols" [rows]="10" [paginator]="true"
        [(selection)]="selectedUser" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
  
        <ng-template pTemplate="header" let-columns>
          <tr >
            
            <th  [pSortableColumn]="col.field"  [title]="col.header" *ngFor="let col of columns"  [hidden]="!col.is_show"      
               pResizableColumn pReorderableColumn    id="{{ col.field }}" style=" width: max-content !important; border-radius: 0px !important;">
              {{ col.header }}
    
            </th>
            <th id="buttons">

            </th>
            
          
          </tr>
         
        </ng-template>
       
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData"  style="height: 50px;">
            
            <td class="pt-0 pb-0" *ngFor="let col of columns; let i =index"  [hidden]="!col.is_show"   
             id="{{ col.field }}" style="max-width: 250px;max-height: 50px;"  >
             <p class="m-0" *ngIf="col.field == 'isAdmin' || col.field == 'isNotifee'"> <span *ngIf="rowData[col.field]">Yes</span><span *ngIf="!rowData[col.field]">No</span></p>
             <p class="m-0" *ngIf="col.field == 'companyName' || col.field == 'emailAddress'|| col.field == 'lastName'|| col.field == 'firstName'"> {{rowData[col.field]}}</p>
            </td>
            <td class="pt-0 pb-0" style="max-height: 50px;" >
              <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button p-mr-2" style="height:32px !important; width:32px !important" (click)="editUser(rowData,'Update')"></button>
              <button pButton pRipple icon="pi pi-trash" class="p-button-rounded" style="height:32px !important; width:32px !important;color: #ffffff; background: #F59E0B; border: 1px solid #F59E0B;" (click)="deleteUser(rowData)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{users ? users.length : 0 }} Users.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="userDialog" [style]="{width: '450px'}" header="User Details" [modal]="true" [closable]="false" styleClass="p-fluid">
  
  <div class="progress-spinner" *ngIf="showspinner"  >
    <p-progressSpinner [style]="{width: '100px', height: '100px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"> </p-progressSpinner>
    <span class="progresstext">User Updating Is In Progress</span>
  </div>
  
  <ng-template pTemplate="content">


    <form [formGroup]="userRegistrationForm" >
      <div class="row">
        <div class="col-md-12 ">
        <div class="row" style="align-items: inherit">
          <div class="col-md-12 cust-form-group pb-2">
            <div class="form-group">
              <label class="cust-form-label" style="margin: revert;"  for="firstName">First Name
                <span class="asterikClass">*</span>
              </label>
              <app-dynamic-form-fields formControlName="firstName" width="100%"  fieldType="inputText"
              placeholder="First Name" >
          </app-dynamic-form-fields>                      </div>
            <div *ngIf="
                userRegistrationForm.get('firstName')?.invalid &&
                userRegistrationForm.get('firstName')?.touched
              " class="text-danger">
              <div *ngIf="
                  userRegistrationForm.get('firstName')?.hasError('required')
                ">
                First Name required
              </div>
             
            </div>
          </div>
          <div class="col-md-12 cust-form-group pb-2">
            <div class="form-group">
              <label class="cust-form-label" style="margin: revert;"  for="lastName">Last Name
                <span class="asterikClass">*</span>
              </label>
              <app-dynamic-form-fields formControlName="lastName" width="100%"  fieldType="inputText"
              placeholder="Last Name" >
          </app-dynamic-form-fields>  
        
            <div *ngIf="
                userRegistrationForm.get('lastName')?.invalid &&
                userRegistrationForm.get('lastName')?.touched && userRegistrationForm.get('lastName')?.hasError('required')
              " class="text-danger">                Last Name required
            </div>
           
            </div>
          </div>

          <div class="col-md-12 cust-form-group pb-2">
            <div class="form-group">
              <label class="cust-form-label" style="margin: revert;"  for="emailAddress">Email
                <span class="asterikClass">*</span>
              </label>
              <app-dynamic-form-fields formControlName="emailAddress" width="100%"  fieldType="inputText"
              placeholder="Email" (input)="checkemail($event)">
          </app-dynamic-form-fields>                      </div>
           
              <div *ngIf="userRegistrationForm.get('emailAddress')?.invalid &&
              userRegistrationForm.get('emailAddress')?.touched 
                " class="text-danger">
                Enter is  valid Email Address
              </div>
              <div *ngIf="userRegistrationForm.get('emailAddress')?.valid && checkduplicateemail() && !editform" class="text-danger">
                Email Address already registered
              </div>
              <div *ngIf="userRegistrationForm.get('emailAddress')?.valid && checkmodifiedduplicate() && editform" class="text-danger">
                Email Address already registered
              </div>
             
          </div>
            <div class="col-md-12 cust-form-group pb-2" >
              <div class="form-group">
                <label class="cust-form-label"  for="companyName">Company
                  <span  class="asterikClass">*</span>
                </label>

                <app-dynamic-form-fields formControlName="companyName" fieldType="dropdown"
                placeholder="Select Company" optionLabel="companyName" width="100%" 
                [options]="CompanyData" (blur)="valueChanged($event)">
            </app-dynamic-form-fields>
          
              </div>
              <div *ngIf="
                  userRegistrationForm.get('companyName')?.invalid &&
                  userRegistrationForm.get('companyName')?.touched
                " class="text-danger">
                <div *ngIf="
                    userRegistrationForm.get('companyName')?.hasError('required')
                  ">
Company is not selected
                </div>
              </div>
            </div>

            <div class="col-md-12 cust-form-group pb-2" *ngIf= "checkdisplay()">
              <div class="form-group">
                
                <app-dynamic-form-fields formControlName="isAdmin" width="100%"  fieldType="checkbox"
               checkBoxMessage="Is Admin" >
            </app-dynamic-form-fields>                      </div>
             
            </div>
          
            <div class="col-md-12 cust-form-group pb-2"  *ngIf= "checkdisplay()">
              <div class="form-group">
                
                <app-dynamic-form-fields formControlName="isNotifee" width="100%"  fieldType="checkbox"
                checkBoxMessage="Send all booking notifications." >
            </app-dynamic-form-fields>                      </div>
             
            </div>
         
          </div>
          </div>
      </div>
      </form>

  </ng-template>
    
    <ng-template pTemplate="footer">
      <div style="text-align:left !important; display: flex;">
        <p-button  label="Cancel"  (click)="hideDialog()" icon="pi pi-times" [disabled]="userRegistrationForm.invalid" styleClass="p-button-secondary" style="opacity: 60%; display: grid; width: 180px !important; " ></p-button>
        
         <p-button *ngIf="!editform" label="Save"  (click)="saveUser('Insert')" icon="pi pi-check" [disabled]="userRegistrationForm.invalid || checkduplicateemail()" style=" display: grid; width: 180px !important; " styleClass="p-button-submit" ></p-button>
         <p-button *ngIf="editform" label="Update"  (click)="saveUser('Update')" icon="pi pi-check" style=" display: grid; width: 180px !important; " styleClass="p-button-submit " [disabled]="userRegistrationForm.invalid || checkmodifiedduplicate()"  ></p-button>
                   
      </div>
        
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
</div>