import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DynamicFormFieldsComponent } from './components/common/dynamic-form-fields/dynamic-form-fields.component';
import { ValidatorMessagePipe } from './pipes/validator-message.pipe';
import { HasRequiredFieldPipe } from './pipes/has-required-field.pipe';
import { TitleCase } from './pipes/title-case.pipe';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipsModule } from 'primeng/chips';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DividerModule } from 'primeng/divider';

import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SlideMenuModule } from 'primeng/slidemenu';
import {MenuModule} from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { ListboxModule } from 'primeng/listbox';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {ContextMenuModule} from 'primeng/contextmenu';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import { MessageService,ConfirmationService } from 'primeng/api';
import {ProgressBarModule} from 'primeng/progressbar';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ProgressSpinnerModule} from 'primeng/progressspinner';


import { TabMenuModule } from 'primeng/tabmenu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';


import { NgxSpinnerModule } from 'ngx-spinner';

import { MeetingComponent } from './components/meeting/meeting.component';

import { UserregistrationComponent } from './components/admin/userregistration/userregistration.component';
import { Time24to12Pipe } from './pipes/time24to12.pipe';
import { CardModule } from "primeng/card";
import { CalendarComponent } from './components/calendar/calendar.component';
import { HttpconfigInterceptor } from './_helpers/interceptor/httpconfig.interceptors';
import {  PermissionsService } from './_helpers/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
  

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    
    HomeComponent,

    
    DynamicFormFieldsComponent,
    
    ValidatorMessagePipe,
    HasRequiredFieldPipe,
    TitleCase,

    MeetingComponent,

    UserregistrationComponent,
    Time24to12Pipe,

    CalendarComponent,
    UnauthorizedComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    InputNumberModule,
    ChipsModule,
    DynamicDialogModule,
    DropdownModule,
    InputTextModule,
    RadioButtonModule,
    CheckboxModule,
    ButtonModule,
    NgxSpinnerModule,
    TooltipModule ,
    AccordionModule ,
    PanelMenuModule,
    ToolbarModule,
     DialogModule,
     CardModule,
      FieldsetModule,
       KeyFilterModule,
       DividerModule,
       CalendarModule,
       TabViewModule,
       TableModule,
       InputSwitchModule,
       SlideMenuModule,
       MenuModule,
        MultiSelectModule,
        ContextMenuModule,
        SliderModule,
         ListboxModule ,
         SidebarModule,
          ConfirmDialogModule,
          ToastModule,
          ProgressBarModule,
          ToggleButtonModule,
          TabMenuModule,
           AutoCompleteModule,
           ProgressSpinnerModule,
           
           ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true,
          }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // allowedDomains: ["*"],
        // disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
   AppRoutingModule
  ],
  providers: [
    PermissionsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpconfigInterceptor,
      multi: true,
    },
    ConfirmationService,
    DatePipe,Time24to12Pipe,
    FormGroupDirective,MessageService],
  bootstrap: [AppComponent],
  exports: [HeaderComponent, NgxSpinnerModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
